<template>
	<BasePage v-if="!result && !error">
		<template #default>
			<div class="flex f-column gap-small">
				<Suspense>
					<component :is="transactionPreview" :tx="tx" />

					<template #fallback>
						<div class="loading-animation"></div>
					</template>
				</Suspense>
			</div>

			<div><hr /></div>

			<div class="field">
				<label>Fee</label>
				<SelectFee
					v-if="balanceStore.assetAmounts && (tx.type === 4 || tx.type === 16)"
					v-model:fee="tx.fee"
					v-model:feeAssetId="tx.feeAssetId"
					:walletAssetAmounts="balanceStore.assetAmounts"
				/>
			</div>
		</template>

		<template #footer>
			<ButtonMain variant="link" @click="emit('rejected')">Reject</ButtonMain>
			<ButtonMain :loading="isLoading" @click="execute">Sign</ButtonMain>
		</template>
	</BasePage>

	<BasePage v-else-if="error">
		<template #image>
			<FailureIcon />
		</template>
		<template #header>Failed</template>
		<template #default> Reason: {{ (error as any)?.message || error }} </template>

		<template #footer>
			<ButtonMain @click="emit('rejected')">Ok</ButtonMain>
		</template>
	</BasePage>

	<BasePage v-else>
		<template #image>
			<SuccessIcon />
		</template>
		<template #header>Signed</template>
		<template #footer>
			<!-- TODO toRaw(signedTx) not deep, shallowRef dont fixed id -->
			<ButtonMain
				v-if="props.broadcast"
				variant="link"
				@click="telegram.WebApp.openLink(walletStore.chainDetails.tx(result!.id))"
			>
				Explorer
			</ButtonMain>
			<ButtonMain @click="emit('signed', result!)">Ok</ButtonMain>
		</template>
	</BasePage>
</template>

<script setup lang="tsx">
import { computed, ref, type Component, watch } from 'vue';
import type { SignedTx, SignerTx } from '@waves/signer';
import { useWalletStore } from '@/stores/useWalletStore';
import * as WavesTransactions from '@waves/waves-transactions';
import TheTransactionInvoke from '@/components/transactions/preview/TheTransactionInvoke.vue';
import TheTransactionTransfer from '@/components/transactions/preview/TheTransactionTransfer.vue';
import TheTransactionDefault from '@/components/transactions/preview/TheTransactionDefault.vue';
import SelectFee from '@/components/transactions/SelectFee.vue';
import {
	type Transaction,
	type SignedTransaction,
	type TransactionMap,
	type TransactionType,
	type WithId
} from '@waves/ts-types';
import ButtonMain from '@/components/ui/BaseButton.vue';
import SuccessIcon from '@/components/icons/SuccessIcon.vue';
import FailureIcon from '@/assets/icons/failure.svg?component';
import { shallowRef } from 'vue';
import { useBalanceStore } from '@/stores/useBalanceStore';
import BasePage from '../ui/BasePage.vue';
import { useTelegram } from '@/composables/useTelegram';
import { useAsyncState } from '@vueuse/core';

const walletStore = useWalletStore();
const balanceStore = useBalanceStore();
const { telegram } = useTelegram();

const props = defineProps<{
	tx: SignerTx;
	broadcast?: boolean;
	callback?: (value: SignedTransaction<Transaction> & WithId) => any | Promise<any>;
	networkByte?: number;
}>();

const transactionPreview = computed(() => {
	const dict: Record<number, Component> = {
		16: TheTransactionInvoke,
		4: TheTransactionTransfer
	};

	return dict[props.tx.type] || TheTransactionDefault;
});

const txChainId = props.networkByte || walletStore.chainId;

const tx = ref<Transaction>(
	WavesTransactions.makeTx({
		chainId: props.networkByte || walletStore.chain.charCodeAt(0),
		senderPublicKey: walletStore.publicKey,
		...props.tx
	} as any)
);

const {
	state: result,
	isLoading,
	error,
	execute
} = useAsyncState(
	async () => {
		let broadcastResult;

		const signedTx = walletStore.sign(tx.value);

		if (props.broadcast)
			broadcastResult = await WavesTransactions.broadcast(signedTx, walletStore.chainDetails.node);

		await props.callback?.(signedTx);

		return broadcastResult || signedTx;
	},
	null,
	{ immediate: false }
);

watch([result, isLoading, error], () => {
	console.log(result.value, isLoading.value, error.value);
});

const emit = defineEmits<{
	(e: 'signed', signedTx: SignedTransaction<Transaction> & WithId): any;
	(e: 'rejected'): any;
}>();
</script>

<style scoped>
hr {
	border: none;
	border-bottom: 2px solid var(--section-separator-color);
}

.image {
	width: 60%;
}
</style>
