import { assetIdToString } from '@/utils';

export type TAssetId = string | null;

export interface IAssetCore {
    assetId: TAssetId;
    decimals: number;
    name: string;
    minSponsoredAssetFee?: number;
    sponsorBalance?: number;
    quantity: number;
    reissuable: boolean;
    description: string;
    script?: string;
}

export interface IAssetPriced {
    price?: number;
}

export interface IAssetIconed {
    icon?: string;
}

export interface IAsset extends IAssetCore, IAssetPriced, IAssetIconed { }

export class Asset implements IAsset {
    assetId: TAssetId;
    decimals: number;
    name: string;
    minSponsoredAssetFee?: number;
    sponsorBalance?: number;
    quantity: number;
    reissuable: boolean;
    description: string;
    script?: string;

    price?: number;
    icon?: string;

    constructor(asset: IAsset) {
        this.assetId = asset.assetId;
        this.decimals = asset.decimals;
        this.name = asset.name;
        this.minSponsoredAssetFee = asset.minSponsoredAssetFee;
        this.sponsorBalance = asset.sponsorBalance;
        this.quantity = asset.quantity;
        this.reissuable = asset.reissuable;
        this.description = asset.description;
        this.script = asset.script;

        this.price = asset.price;
        this.icon = asset.icon;
    }

    toString() {
        return assetIdToString(this.assetId);
    }

    get key() {
        return assetIdToString(this.assetId);
    }

    amountToFloat(amount: bigint | string | number) {
        return Number(amount) / Math.pow(10, this.decimals);
    }

    amountfromFloat(float: number) {
        return Math.ceil(float * Math.pow(10, this.decimals));
    }

    amountToString(amount: bigint | string | number) {
        return this.amountToFloat(amount).toFixed(this.decimals);
    }
}

export const WAVES: IAssetCore = {
    assetId: null,
    decimals: 8,
    name: 'WAVES',
    description: 'Waves',
    minSponsoredAssetFee: 100000,
    sponsorBalance: Infinity,
    quantity: Infinity,
    reissuable: false,
}