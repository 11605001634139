<template>
	<h1>Invoke</h1>

	<div class="field">
		<label>Dapp</label>

		<input :value="props.tx.dApp" readonly />
	</div>

	<div v-if="props.tx.call?.function" class="field">
		<label>Callable function</label>

		<input :value="props.tx.call.function" readonly />
	</div>

	<div v-if="props.tx.call?.args" class="field">
		<label>Args</label>

		<div class="table-container">
			<table>
				<tr>
					<th>type</th>
					<th>value</th>
				</tr>
				<tr v-for="(arg, index) of props.tx.call.args" :key="index">
					<td class="table-cell">{{ arg.type }}</td>
					<td class="table-cell">{{ arg.value }}</td>
				</tr>
			</table>
		</div>
	</div>

	<div v-if="payments.length > 0" class="field">
		<label>Payments</label>

		<AssetAmountItem v-for="payment of payments" :key="payment.key" :assetAmount="payment" />
	</div>
</template>

<script setup lang="ts">
import AssetAmountItem from '@/components/AssetAmountItem.vue';
import ExpandContent from '@/components/ui/ExpandContent.vue';
import { AssetAmount } from '@/libs/asset-amount';
import { useAssets } from '@/services/useAssets';
import { type InvokeScriptTransaction } from '@waves/ts-types';

const props = defineProps<{
	tx: InvokeScriptTransaction;
}>();

const paymentAssets = await useAssets((props.tx.payment || []).map((e) => e.assetId));

let payments = paymentAssets.map((asset, index) => {
	console.log(asset, props.tx.payment![index].amount, index);

	return new AssetAmount(asset, props.tx.payment![index].amount);
});

// if (props.tx.payment) {
// 	const paymentsAssets = await useAssets(props.tx.payment.map((e) => e.assetId));

// 	const a = paymentsAssets.map((asset, index) => {
// 		console.log(asset, props.tx.payment![index].amount, index);

// 		return new AssetAmount(asset, props.tx.payment![index].amount);
// 	});
// }
</script>

<style scoped>
.table-container {
	width: 100%;
	overflow-x: auto;
}

.table-cell {
	word-break: break-all;
}

td:first-child {
	white-space: nowrap;
}
</style>
