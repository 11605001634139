export function formatAddress(address: string) {
    if (address === 'WAVES') return address;

    return `${address.slice(0, 4)}...${address.slice(address.length - 4, address.length)}`;
}

export function assetIdToString(assetId: string | null) {
    return assetId ? assetId : 'WAVES';
}

export function assetIdFromString(string: string) {
    return string === 'WAVES' ? null : string;
}

export function removeFocus() {
    if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
    }
}