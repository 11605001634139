<template>
	<SelectAssetAmount v-model="feeAssetAmount" :assetAmounts="aviableFeeAssetAmounts" :showAmount="true" />
</template>

<script setup lang="ts">
//score - 8/10
//aviableFeeAssetAmounts можно сделать не computed, а const calculated один раз, но только в том случае, если fee не будет меняться в родителе
import { computed, defineModel, watch } from 'vue';
import SelectAssetAmount from '@/components/ui/SelectAssetAmount.vue';
import { AssetAmount } from '@/libs/asset-amount';
import { Asset, WAVES } from '@/libs/asset';

const props = defineProps<{
	walletAssetAmounts: AssetAmount[];
	disabled?: boolean;
}>();

const fee = defineModel<number | string>('fee', {
	required: true
});
const feeAssetId = defineModel<null | string>('feeAssetId', {
	required: true
});

const feeAsset = computed(() => props.walletAssetAmounts.find((e) => e.asset.assetId == feeAssetId.value)?.asset);

const feeAssetAmount = computed({
	get: () => {
		return new AssetAmount(feeAsset.value!, fee.value);
	},
	set: (val) => {
		feeAssetId.value = val.asset.assetId;
		fee.value = Number(val.amount);
	}
});

function minSponsoredFee(asset: Asset) {
	return asset.assetId === null ? 100_000 : asset.minSponsoredAssetFee;
}

function convertFeeToAsset(fee: AssetAmount<Asset>, asset: Asset) {
	return new AssetAmount(
		asset,
		(fee.amount * BigInt(minSponsoredFee(asset)!)) / BigInt(fee.asset.minSponsoredAssetFee!)
	);
}

const aviableFeeAssetAmounts = computed(() => {
	const feeInWaves = convertFeeToAsset(feeAssetAmount.value, new Asset(WAVES));

	return props.walletAssetAmounts
		.filter(
			(e) =>
				e.asset.minSponsoredAssetFee &&
				e.asset.sponsorBalance &&
				e.asset.sponsorBalance > Number(feeInWaves.amount)
		)
		.map((e) => {
			return {
				fee: convertFeeToAsset(feeAssetAmount.value, e.asset),
				walletAssetAmount: e
			};
		})
		.filter((e) => e.fee.amount <= e.walletAssetAmount.amount)
		.map((e) => e.fee);
});

if (!feeAsset.value) {
	feeAssetAmount.value = aviableFeeAssetAmounts.value[0];
}
</script>
