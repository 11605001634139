<template>
	<AssetAmountItem v-if="showAmount" :assetAmount="selectedAssetAmount" class="pointer" @click="modal.show()" />
	<AssetItem v-else :asset="selectedAssetAmount.asset" class="pointer" @click="modal.show()" />
</template>

<script setup lang="ts">
import { defineModel, defineProps } from 'vue';
import { Asset, WAVES } from '@/libs/asset';
import { AssetAmount } from '@/libs/asset-amount';
import AssetItem from '@/components/AssetItem.vue';
import AssetAmountItem from '@/components/AssetAmountItem.vue';
import SelectAssetAmountModal from '@/components/modals/SelectAssetAmountModal.vue';
import { useModal } from '@/stores/useModalStore';

const props = defineProps<{
	assetAmounts: AssetAmount[];
	showAmount?: boolean;
}>();

const selectedAssetAmount = defineModel<AssetAmount>({
	default: new AssetAmount(new Asset(WAVES), 0)
});

const modal = useModal({
	component: SelectAssetAmountModal,
	props: {
		assetAmounts: props.assetAmounts
	},
	onClose(assetAmount?: AssetAmount) {
		if (assetAmount) selectedAssetAmount.value = assetAmount;
	}
});
</script>

<style scoped></style>
