import { Asset, WAVES } from '@/libs/asset';
import { useBackendApi } from './useBackendApi';
import { useNodeApi } from './useNodeApi';
import { AssetAmount } from '@/libs/asset-amount';
import { MAX_ASSETS_PER_REQUEST } from '@/api/node';
import { useAssetStore } from '@/stores/useAssetStore';

export const useBalance = async (address: string) => {
    const assetStore = useAssetStore();
    const nodeApi = useNodeApi();
    const backendApi = useBackendApi();

    const [wavesBalance, assetsBalance] = await Promise.all([nodeApi.Address.balance(address), nodeApi.Assets.balance(address)])

    const assetsAddresses = assetsBalance.balances.map(e => e.assetId);

    const assetsInfo = await backendApi.Assets.info({ assets: [...assetsAddresses, 'WAVES'] });

    let assetAmouts: AssetAmount<Asset>[] = [new AssetAmount(new Asset({ ...WAVES, price: assetsInfo['WAVES']?.rate, icon: assetsInfo['WAVES']?.logo }), wavesBalance.balance)]

    for (let i = 0; i < assetsBalance.balances.length; i += MAX_ASSETS_PER_REQUEST) {
        const assetsDetails = await nodeApi.Assets.details(assetsAddresses.slice(i, i + MAX_ASSETS_PER_REQUEST))

        assetAmouts = assetAmouts.concat(
            assetsDetails.map((e, id) =>
                new AssetAmount(new Asset({
                    ...e,
                    price: assetsInfo[e.assetId]?.rate || 0,
                    icon: assetsInfo[e.assetId]?.logo,
                    sponsorBalance: assetsBalance.balances[i + id].sponsorBalance
                }),
                    assetsBalance.balances[i + id].balance
                )
            )
        )
    }

    assetStore.setAssets(assetAmouts.map(assetAmount => assetAmount.asset))

    return assetAmouts;
}