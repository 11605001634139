<template>
	<template v-for="(modal, index) in modals" :key="index">
		<Teleport to="#app">
			<Transition name="overlay" appear>
				<div v-if="modal.isShow" class="over" @click="closed(modal)"></div>
			</Transition>
			<Transition name="pop" appear>
				<div v-if="modal.isShow" class="container" @click.self="removeFocus">
					<Suspense>
						<Component
							:is="modal.component"
							v-bind="{ ...modal.props }"
							@closed="(...params: any[]) => closed(modal, ...params)"
						>
							<template v-for="(slot, key) in modal.slots" #[key] :key="key">
								<component :is="slot" />
							</template>

							<!-- <template v-for="(_, slotName) in modal.slots" v-slot:[slotName]="slotProps">
						    <slot :name="slotName" v-bind="slotProps ?? {}" />
						</template> -->
						</Component>

						<template #fallback> Loading... </template>
					</Suspense>
				</div>
			</Transition>
		</Teleport>
	</template>
</template>

<script setup lang="ts">
import { useModalStore, type IUseModalParams } from '@/stores/useModalStore';
import { removeFocus } from '@/utils';

const { modals } = useModalStore();

const closed = (modal: IUseModalParams, ...params: any[]) => {
	modal.isShow = false;
	modal.onClose?.(...params);
};
</script>

<style scoped>
.over {
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 9;
	background-color: rgba(0, 0, 0, 0.6);
	cursor: pointer;
}

.container {
	position: fixed;
	bottom: 0px;
	left: 0px;
	right: 0px;
	height: 80vh;

	background-color: var(--background-color);

	border-radius: 20px 20px 0px 0px;

	z-index: 10;

	display: flex;
	flex-direction: column;

	overflow: auto;
	overscroll-behavior: contain;
}

.overlay-enter-active,
.overlay-leave-active {
	transition: opacity 0.5s;
}

.overlay-enter-from,
.overlay-leave-to {
	opacity: 0;
}

.pop-enter-active,
.pop-leave-active {
	transition: bottom 0.5s, height 0.5s;
}

.pop-enter-from,
.pop-leave-to {
	height: 0px;
	bottom: -80vh;
}
</style>
