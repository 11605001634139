<template>
	<BasePage>
		<template #header>Input password</template>
		<template #default>
			<span v-if="result === false">Incorrect password</span>
			<input v-model="password" type="password" />
		</template>
		<template #footer>
			<BaseButton variant="link" class="button--link" @click="wipe()">Wipe data</BaseButton>
			<button @click="login()">Login</button>
		</template>
	</BasePage>
</template>

<script setup lang="ts">
import { useTelegram } from '@/composables/useTelegram';
import { useWalletStore } from '@/stores/useWalletStore';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import BasePage from './ui/BasePage.vue';
import BaseButton from './ui/BaseButton.vue';

const router = useRouter();
const wallet = useWalletStore();
const { telegram } = useTelegram();

const password = ref<string>('');
const result = ref<boolean>();

const login = () => {
	result.value = wallet.decrypt(password.value);
};

const wipe = () => {
	telegram.WebApp.showConfirm('are you sure?', (ok) => {
		if (ok) {
			wallet.clear();
			router.push('/');
		}
	});
};
</script>
