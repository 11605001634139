<template>
	<h1>Transfer</h1>

	<div class="field">
		<label>Asset</label>

		<AssetItem :asset="asset" />
	</div>

	<div class="field">
		<label>Amount</label>

		<input :value="asset.amountToString(tx.amount)" readonly />
	</div>

	<div class="field">
		<label>Recipient</label>

		<input :value="tx.recipient" readonly />
	</div>
</template>

<script setup lang="ts">
import { type TransferTransaction } from '@waves/ts-types';
import AssetItem from '@/components/AssetItem.vue';
import { useAsset } from '@/services/useAsset';

const props = defineProps<{
	tx: TransferTransaction;
}>();

const asset = await useAsset(props.tx.assetId);
</script>
