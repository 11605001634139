import { Asset } from './asset';

export class AssetAmount<T extends Asset = Asset> {
    asset: T;
    amount: bigint;

    constructor(asset: T, amount: string | number | bigint) {
        this.asset = asset;
        this.amount = BigInt(amount);
    }

    toString() {
        return `${this.amountToString()} ${this.asset.toString()}`
    }

    amountToString() {
        return `${this.amountToFloat().toFixed(this.asset.decimals)}`
    }

    amountToFloat() {
        return Number(this.amount) / Math.pow(10, this.asset.decimals);
    }

    inUsd() {
        return this.asset.price ? this.amountToFloat() * this.asset.price : 0;
    }

    get key() {
        return this.asset.key;
    }
}